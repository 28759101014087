const env = (typeof process !== "undefined" ? process.env : import.meta.env) as Record<string, string>;

export const BACKEND_HOST = env.VITE_BACKEND_HOST;

export const LIVEBLOCKS_SECRET = env.LIVEBLOCKS_SECRET;
export const LIVEBLOCKS_PUBLIC_API_KEY = env.LIVEBLOCKS_PUBLIC_API_KEY;
export const LIVEBLOCKS_WEBHOOK_SECRET = env.LIVEBLOCKS_WEBHOOK_SECRET ?? "";

export const AUTH0_SECRET = env.AUTH0_SECRET ?? "";
export const AUTH0_BASE_URL = env.AUTH0_BASE_URL ?? "";
export const AUTH0_ISSUER_BASE_URL = env.AUTH0_ISSUER_BASE_URL ?? "";
export const AUTH0_CLIENT_ID = env.AUTH0_CLIENT_ID ?? "";
export const AUTH0_CLIENT_SECRET = env.AUTH0_CLIENT_SECRET ?? "";
export const AUTH0_LOGOUT_URL = env.AUTH0_LOGOUT_URL ?? "";

export const GOOGLE_PROJECT_ID = "smooth-drive-419712";
export const GOOGLE_LOCATION = "europe-west1";

export const ATLASSIAN_CLIENT_ID = env.ATLASSIAN_CLIENT_ID ?? "";
export const ATLASSIAN_CLIENT_SECRET = env.ATLASSIAN_CLIENT_SECRET ?? "";
export const ATLASSIAN_SCOPE =
	env.ATLASSIAN_CLIENT_SCOPE ?? "offline_access read:jira-work write:jira-work read:me manage:jira-webhook";

export const PUBLIC_HOST = env.PUBLIC_HOST ?? "";

export const INTERCOM_APP_ID = env.VITE_INTERCOM_APP_ID ?? "";
export const INTERCOM_SECRET = env.INTERCOM_SECRET ?? "";

export const POSTHOG_KEY = env.VITE_POSTHOG_KEY ?? null;

export const STRIPE_PUBLIC_KEY = env.VITE_STRIPE_PUBLIC_KEY ?? "";
export const STRIPE_PRICING_TABLE_ID = env.VITE_STRIPE_PRICING_TABLE_ID ?? "";
export const STRIPE_SECRET_KEY = env.STRIPE_SECRET_KEY ?? "";
export const STRIPE_WEBHOOK_SECRET = env.STRIPE_WEBHOOK_SECRET ?? "";

export const FIGMA_CLIENT_ID = env.FIGMA_CLIENT_ID ?? "";
export const FIGMA_SECRET = env.FIGMA_SECRET ?? "";
export const FIGMA_SCOPES = "files:read,webhooks:write";
